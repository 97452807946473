import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import vueAwesomeSidebar from 'vue-awesome-sidebar';
import vue3Shortkey from 'vue3-shortkey';
import 'vue3-easy-data-table/dist/style.css';
import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css';
import 'bootstrap';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vueAwesomeSidebar);
app.use(vue3Shortkey);
app.component('EasyDataTable', Vue3EasyDataTable);
app.mount('#app');
