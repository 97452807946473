<template>
    <div class="modal fade modal-default" id="modalEnterprise" aria-hidden="true" aria-labelledby="modalEnterpriseLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content d-flex justify-content-center">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalEnterpriseLabel">{{modalTitle}}</h5>
                    <i data-bs-dismiss="modal" aria-label="Close" class="siscont icon_x" id="btnCloseModalEnterprise"/>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12">
                      
                    </div>
                  </div>
                   
                   <div class="mb-3">
                      <label class="form-label">Empresa</label>
                      <select v-model="enterpriseId" class="form-select">
                          <option v-for="optEnterprise in $store.getters.getEnterprises" :key="optEnterprise.id" :value="optEnterprise.id">
                              {{optEnterprise.name}}
                          </option>
                      </select>
                    </div>

                </div>
                <div class="modal-footer d-flex justify-content-end">
                    <button type="button" class="btn btn-white" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-blue"  data-bs-dismiss="modal" @click="setCurrentEntreprise">{{txtBtnModal}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'ModalEnterprise',
    props: {
      modalTitle: String,
      txtBtnModal: String,
    },
    data () {
      return {
        enterpriseId: this.$store.getters.getEnterpriseId
      }
    },
    methods: {
      setCurrentEntreprise() {
        if (this.$store.getters.getEnterpriseId != this.enterpriseId) {
          this.$store.commit('setEnterpriseId',this.enterpriseId)
          this.$router.go(this.$router.currentRoute)
        }
      },
      checkClosedModal() {
        var myModal = document.getElementById('modalTypeOrder')
        myModal.addEventListener('hidden.bs.modal', (e) => this.eventListenerCloseModalEnterprise(e))
      },
      eventListenerCloseModalEnterprise(e) {
        const enterpriseId = this.$store.getters.getEnterpriseId
        if (!enterpriseId) {
          // alert("Escolha a empresa")
          document.getElementById('btnOpenModalEnterprise').click();
          return false
        }

        return true
      }
    },
    watch: {
    '$store.state.enterpriseId': {
        immediate: true,
        handler(newEnterpriseId, oldEnterpriseId) {
          if (newEnterpriseId && (newEnterpriseId != this.enterpriseId)) {
            this.enterpriseId = newEnterpriseId
          }
        }
     },
    },
    mounted() {
      var myModal = document.getElementById('modalEnterprise')
      myModal.addEventListener('hidden.bs.modal', (e) => this.eventListenerCloseModalEnterprise(e))
      if (!this.enterpriseId && this.$route.name != 'orderCreate') {
        document.getElementById('btnOpenModalEnterprise').click()
      }
    },
  };
</script>