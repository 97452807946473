const copyClipboard = function(event, info) {
    const parentElement = event.target.parentNode.parentNode
    const textArea = document.createElement("textarea");
    textArea.style.cssText = 'text-transform: none;';
    textArea.value = info;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        parentElement.classList.add('copied');
        parentElement.querySelector('.key-copied').classList.remove('d-none');
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
};

const copyClipboardByModal = function(event, info, modalId) {
    const parentElement = event.target.parentNode.parentNode
    const textArea = document.createElement("textarea");
    textArea.style.cssText = 'text-transform: none;';
    const modal = document.getElementById(modalId)
    textArea.value = info
    modal.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        parentElement.classList.add('copied');
        parentElement.querySelector('.key-copied').classList.remove('d-none');
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    modal.removeChild(textArea);
};

export {copyClipboard, copyClipboardByModal}
