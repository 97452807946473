<template>
  <div class="container page-login">
    <div class="row login-box">
      <div class="col">
        <div class="row">
          <form @submit.prevent="onSubmit">
            <h3>{{titleLogin}}</h3>
            <div class="form-group">
              <p v-if="showForgot">
                {{infoForgot}}
              </p>
              <a href="#" class="link-forgot" v-if="!showInputEmail" @click="setLogin">Voltar para login</a>
            </div>
            
            <div class="form-group" :class="{ error: v$.form.email.$errors.length }" v-if="showInputEmail">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="email" v-model="v$.form.email.$model" @blur="v$.form.email.$commit">
              <a href="#" class="link-forgot" v-if="showForgot" @click="setLogin">Voltar</a>
              <!-- error message -->
              <div class="input-errors" v-for="error of v$.form.email.$errors" :key="error.$uid">
                <div class="error-msg alert alert-secondary">
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <div class="form-group" :class="{ error: v$.form.password.$errors.length }" v-if="showLogin">
              <label for="password">Senha</label>
              <input type="password" class="form-control" id="password" v-model="v$.form.password.$model" @blur="v$.form.password.$commit">
              <i class="icon-password" id="change-type-password" @click="showPassword"/>
              <a href="#" class="link-forgot" v-if="showLogin" @click="setForgotPassword">Esqueceu a senha</a>
              
               <!-- error message -->
              <div class="input-errors erros-password" v-for="error of v$.form.password.$errors" :key="error.$uid">
                <div class="error-msg alert alert-secondary">
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <div v-if="msg" class="error-login alert alert-dark" role="alert">
              <span>{{msg}}</span>
            </div>
            <div class="action" v-if="showInputEmail">
              <button :disabled="v$.form.$invalid" class="btn btn-primary">{{txtBtn}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="footer-login">
      <img src="../../public/img/logo_quadrada.png">
      <span>Um sistema CPAPS</span>
    </div>
  </div>
  <LoadingCpaps :showLoading="showLoading" />
</template>
<script>
  import useVuelidate from '@vuelidate/core'
  import LoadingCpaps from '../components/LoadingCpaps.vue'
  import { required, requiredIf, email, helpers } from '@vuelidate/validators'

  export default {
    setup () {
      return { v$: useVuelidate({ $rewardEarly: true }) }
    },
     components: {
        LoadingCpaps,
    },
    data() {
      return {
        form: {
          email: '',
          password: '' 
        },
        titleLogin: "Acesse sua conta!",
        msg: null,
        showLogin: true,
        showForgot: false,
        showInputEmail: true,
        infoForgot: "Digite o seu usuário que enviaremos um e-mail com instruções para redefinição da senha.",
        txtBtn: "Acessar",
        showLoading: false
      }
    },
    methods: {
      async onSubmit(event) {
        event.preventDefault()
        const hasValidete = await this.v$.$validate()
        if (hasValidete)
        {
          this.showLoading = true;
          const data = JSON.stringify(this.form);
          if (this.showLogin)
          {
            const hasLogged = await this.$store.dispatch('login', data);
            if (hasLogged)
            {
              const hasUser = await this.$store.dispatch('getInfoUserLogged');
              if (hasUser)
              {
                const redirect = this.$route.redirectedFrom ?? {name: "home"}
                this.$router.push(redirect) 
              }
            }
            else {
              this.msg = "Email ou senha invalidos !"

              setTimeout(() => {
                    this.msg = ""
                }, 5000);
            }
          }
          else
          {
            const data = JSON.stringify(this.form);
          
            const requestOptions = await this.$store.dispatch('getRequestOptions', {addAuth: false,method: "POST", dataJson: data});

            const dataRequest = await this.$store.dispatch('sendRequest', {url: '/forgot-password', requestOptions: requestOptions})
            console.log(dataRequest);
            if (dataRequest.request.status == 200) {
              this.setSucessForgot()
            }
            else {
              this.msg = dataRequest.response.msg ?? 'Error ao enviar solicitação da troca de senha.'

              setTimeout(() => {
                    this.msg = ""
                }, 5000);
            }
          }
          this.showLoading = false;
        }
      },
      showPassword () {
        let elInput = document.getElementById('password');
        let elementBtn = document.getElementById('change-type-password');
        if (elementBtn.classList.contains('icon-eye')) {
          elInput.type="password";
          elementBtn.classList.remove('icon-eye');
        }
        else {
          elementBtn.classList.add('icon-eye');
          elInput.type="text";
        }
      },
      setForgotPassword() {
        this.titleLogin = "Redefina sua senha!";
        this.txtBtn = "Enviar";
        this.infoForgot = "Digite o seu usuário que enviaremos um e-mail com instruções para redefinição da senha.";
        this.showLogin = false;
        this.showForgot = true;
      },
      setLogin() {
        this.titleLogin = "Acesse sua conta!";
        this.txtBtn = "Acessar";
        this.showLogin = true;
        this.showForgot = false;
        this.showInputEmail = true;
      },
      setSucessForgot() {
        this.titleLogin = "E-mail enviado!";
        this.infoForgot = "As instruções para a redefinição da senha foram enviadas para o email " +this.form.email+".";
        this.showLogin = false;
        this.showInputEmail = false;
        this.showForgot = true;
      }
    },
    validations() {
      return {
        form: {
          email: {
            required: helpers.withMessage('Campo obrigatório', required),
            email: helpers.withMessage('E-mail inválido', email),
          },
          password: {
              requiredIf: helpers.withMessage('Campo obrigatório', requiredIf(this.showLogin)),
          },
        },
      }
    },
  }
</script>