<template>
    <div v-if="showLoading" class="loading-cpaps text-center">
        <img src="../../public/img/loading_cpaps.gif" class="iimg-fluid" />
    </div>
</template>
<script>
export default {
    name: "LoadingCpaps",
    props: {
        showLoading: Boolean
    }
}
</script>
<style scoped lang="scss">
.loading-cpaps {
    position: absolute;
    top: -80px;
    left: 0;
    z-index: 999999;
    // width: 100%;
    // height: 100%;
    width: 100vw;
    height: 100vw;
    // height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    img {
        width: 15%;
    }
}

@media (max-width: 767px) {
    .loading-cpaps {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999999;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 85%;
        }
    }
}
</style>