// Sets an item with a Key to local storage
const saveStorage = function(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
};

// Looks for a local storage item and returns if present
const getStorage = function(key, item:string=null) {
    if( localStorage.getItem(key) && item) {
        const data = JSON.parse(localStorage.getItem(key))
        return data[item]
    }
    else if(localStorage.getItem(key)) {
        let item = localStorage.getItem(key)
        if (item == 'null') {
            item = null
        }
       return item
    }
};

// Clear a single item or the whole local storage
const clearStorage = function(key:string=null) {
    if(key) {
        localStorage.removeItem(key);
    } else {
        localStorage.clear();
    }
}
// Exports all avaliable functions on the script
export {getStorage, saveStorage, clearStorage}