const CryptoJS = require("crypto-js")

// Sets an item with a Key to local storage
const encryptId = function(param) {
    return encodeURI(CryptoJS.AES.encrypt(param.toString(), 'chama').toString());
};

// Looks for a local storage item and returns if present
const decryptId = function(param) {
    const decrypt = CryptoJS.AES.decrypt(param, 'chama')
    return decodeURI(decrypt.toString(CryptoJS.enc.Utf8))
};

// Exports all avaliable functions on the script
export {encryptId, decryptId}