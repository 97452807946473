<template>
  <div class="">
    <MyHeader @toggle-sidebar="toggleSidebar"/>
    <MySidebar :collapsed="collapsed"/>
    <div class="container-fluid container-main" :class="{logged: $store.getters.getLoggedIn}">
      <MessageModal 
        :message="$store.state.message" 
        :messageType="$store.state.messageType" 
        :redirectRoute="$store.state.redirectRoute"
        @close="resetMessage" 
      />
      <router-view/>
    </div>
  </div>
</template>

<script>
import MyHeader from '@/components/MyHeader.vue';
import MySidebar from '@/components/MySidebar.vue';
import MessageModal from '@/components/MessageModal.vue';

export default {
  components: {
    MyHeader,
    MySidebar,
    MessageModal
  },
  data(){
    return{
      collapsed: false
    }
  },
  methods: {
    resetMessage() {
      this.$store.dispatch('closeMessage');
    },
    toggleSidebar() {
      this.collapsed = !this.collapsed;
    },
    checkIfMobile() {
      this.collapsed = this.$store.getters.isMobile;
    }
  },
  mounted(){
    this.checkIfMobile();
  },
  watch: {
    $route() {
      if (this.$store.getters.isMobile) {
        this.collapsed = true;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
